import type { HeadersFunction, MetaFunction } from "@remix-run/node";
import { HeroSection } from "#client/marketing/Hero.tsx";

import { HowItWorksSection } from "#client/marketing/HowItWorks.tsx";
import {
  FeatureContentDescription,
  FeatureContentHeading,
  FeatureContentItemBullet,
  FeatureContentList,
  FeatureContentListItem,
  FeatureHeader,
  FeatureHeading,
  FeatureSection,
  FeatureSectionContent,
  FeatureSubHeading,
} from "#client/marketing/Features.tsx";
import { PricingSection } from "#client/marketing/Pricing.tsx";
import { FaqSection } from "#client/marketing/Faq.tsx";
import { CanvaTemplateSection } from "#client/marketing/CanvaTemplateSection.tsx";
import { FooterSection } from "#client/marketing/Footer.tsx";
import { type loader as RootLoader } from "#root.tsx";

import { Image } from "@unpic/react";
import { BlogSection } from "#client/marketing/BlogSection.tsx";
import { MarketingNav } from "#client/marketing/MarketingNav.tsx";
import { Tiers } from "#client/payment/constants.ts";
import { useRouteLoaderData } from "@remix-run/react";
import invariant from "tiny-invariant";

import HeroImageGroup from "#client/images/wedding/hero.webp";

import TableNumber from "#client/images/canva-table_number.png";
import ScavengerHunt from "#client/images/canva-scavenger_hunt.png";
import UsPeaceSign from "#client/images/us_peace_sign.webp";

import { LandingSectionHorizontalLayout } from "#client/marketing/LandingPageSection";
import { useChatbotContext } from "#client/chatbot/helpscout";

export const meta: MetaFunction<unknown, { root: typeof RootLoader }> = ({
  matches,
}) => {
  const seoIndex = matches?.find((match) => match.id === "root")?.data.seoIndex;
  const seo =
    seoIndex === true
      ? []
      : [
          {
            name: "robots",
            content: "noindex",
          },
        ];

  return [
    {
      title:
        "QR Code Photo Sharing | Audio & Video Digital Guestbook | EventShare",
    },
    {
      name: "description",
      content:
        "Collect photos, videos and audio recordings from your wedding guests in a private digital gallery! No app install or login needed from guests.",
    },
    {
      name: "keywords",
      content:
        "photo QR code, wedding photo, sharing pictures, photo qr code scanner, pictures for weddings, instant camera guestbook",
    },
    ...seo,
  ];
};

export const headers: HeadersFunction = () => ({
  "Cache-Control": "public, max-age=0, must-revalidate",
  "Cloudflare-CDN-Cache-Control":
    "public, s-maxage=300, stale-while-revalidate=3600",
});

export default function HomePage() {
  const rootLoaderData = useRouteLoaderData<typeof RootLoader>("root");
  invariant(rootLoaderData, "Could not find products to buy");

  const { plusEventProduct, premiumEventProduct } = rootLoaderData;

  const { useShowChatbot } = useChatbotContext();
  useShowChatbot();

  return (
    <div>
      <MarketingNav />
      <HeroSection
        heading="Collect more memories of your wedding with QR Codes"
        subHeading="Guests scan your QR code to upload photos and videos from your wedding. Relive the day through their eyes."
        image={() => (
          <div className="flex justify-center rounded-xl lg:justify-end lg:rounded-2xl">
            <Image
              src={HeroImageGroup}
              layout="constrained"
              height={450}
              width={504}
              alt="Wedding photos"
              className="w-full min-w-[300px] rounded-md"
            />
          </div>
        )}
      />
      <HowItWorksSection
        steps={[
          {
            name: "Create an event",
            description: "Give your event a name and date",
            img: {
              src: "/landing-pages/wedding/create-event.webp",
              height: 525,
              width: 400,
              alt: "create event",
            },
          },
          {
            name: "Share event with guests",
            description: "Print the provided QR code for guests to scan",
            img: {
              src: "/landing-pages/wedding/qr-sign.webp",
              height: 525,
              width: 400,
              alt: "create event",
            },
          },
          {
            name: "Enjoy the uploads",
            description:
              "Scroll your event gallery to relive those precious moments",
            img: {
              src: "/landing-pages/wedding/gallery-howto.webp",
              height: 525,
              width: 400,
              alt: "create event",
            },
          },
        ]}
      />
      <div id="features" className="pt-24 sm:pt-32">
        <LandingSectionHorizontalLayout>
          <FeatureHeader>
            <FeatureHeading>
              Collect photos to remember for a lifetime
            </FeatureHeading>
            <FeatureSubHeading>
              We help you get the pictures your loved ones will take of all the
              romantic, funny, and special moments.
            </FeatureSubHeading>
          </FeatureHeader>

          <FeatureSection direction="regular">
            <div className="sm:flex sm:justify-center">
              <Image
                src="/landing-pages/wedding/feature-gallery.webp"
                layout="constrained"
                height={408}
                width={640}
                alt="Host photo dashboard"
              />
            </div>
            <FeatureSectionContent>
              <FeatureContentHeading>Event gallery</FeatureContentHeading>
              <FeatureContentDescription>
                All photos and videos are sent to the event gallery for easy
                viewing, sharing and download.
              </FeatureContentDescription>
              <FeatureContentList>
                <FeatureContentListItem>
                  <FeatureContentItemBullet />
                  No more hunting down guests to get photos and videos
                </FeatureContentListItem>
                <FeatureContentListItem>
                  <FeatureContentItemBullet />
                  Set photos or videos to private so only you can see
                </FeatureContentListItem>
                <FeatureContentListItem>
                  <FeatureContentItemBullet />
                  No more hunting down guests to get photos and videos
                </FeatureContentListItem>
              </FeatureContentList>
            </FeatureSectionContent>
          </FeatureSection>
        </LandingSectionHorizontalLayout>
        <div className="bg-brand-mint_green-50">
          <LandingSectionHorizontalLayout>
            <FeatureSection direction="reverse">
              <div className="flex w-full justify-center">
                <Image
                  src="/landing-pages/wedding/feature-mobile2.webp"
                  layout="constrained"
                  height={700}
                  width={394}
                  alt="Guest page screenshot"
                />
              </div>
              <FeatureSectionContent className="w-full sm:max-w-2xl lg:max-w-4xl">
                <FeatureContentHeading>
                  Customizable guest page
                </FeatureContentHeading>
                <FeatureContentDescription>
                  The guest page is fully customizable by you. Use your wedding
                  colors, a picture of you and your partner, and leave a welcome
                  message.
                </FeatureContentDescription>
                <FeatureContentList>
                  <FeatureContentListItem>
                    <FeatureContentItemBullet />
                    No app install needed. Works in the browser
                  </FeatureContentListItem>
                  <FeatureContentListItem>
                    <FeatureContentItemBullet />
                    Guest name associated with every item uploaded
                  </FeatureContentListItem>
                  <FeatureContentListItem>
                    <FeatureContentItemBullet />
                    All photos and videos saved in original quality
                  </FeatureContentListItem>
                </FeatureContentList>
              </FeatureSectionContent>
            </FeatureSection>
          </LandingSectionHorizontalLayout>
        </div>
        <div className="bg-gray-50">
          <LandingSectionHorizontalLayout>
            <FeatureSection direction="regular">
              <Image
                src="/landing-pages/wedding/feature-guestbook.webp"
                layout="constrained"
                height={408}
                width={640}
                alt="Digital guestbook"
              />
              <FeatureSectionContent>
                <FeatureContentHeading>Digital guestbook</FeatureContentHeading>
                <FeatureContentDescription>
                  Capture heartfelt messages in video, audio, or written format
                  from your loved ones, including those who could not attend.
                </FeatureContentDescription>
                <FeatureContentList>
                  <FeatureContentListItem>
                    <FeatureContentItemBullet />
                    Greet guests with a custom audio message before they add to
                    your audio and video guestbook
                  </FeatureContentListItem>
                  <FeatureContentListItem>
                    <FeatureContentItemBullet />
                    No max length for written messages
                  </FeatureContentListItem>
                  <FeatureContentListItem>
                    <FeatureContentItemBullet />
                    No time limit for audio or video messages
                  </FeatureContentListItem>
                </FeatureContentList>
              </FeatureSectionContent>
            </FeatureSection>
          </LandingSectionHorizontalLayout>
        </div>
        <div>
          <LandingSectionHorizontalLayout>
            <FeatureSection direction="reverse">
              <Image
                src="/landing-pages/wedding/feature-slideshow.webp"
                layout="constrained"
                height={408}
                width={640}
                alt="Slideshow screenshot"
              />
              <FeatureSectionContent>
                <FeatureContentHeading>Live slideshow</FeatureContentHeading>
                <FeatureContentDescription>
                  Entertain everyone at the reception with a live slideshow. All
                  photos and videos are added to the slideshow in real time.
                </FeatureContentDescription>
                <FeatureContentList>
                  <FeatureContentListItem>
                    <FeatureContentItemBullet />
                    Encourages guests to take more photos
                  </FeatureContentListItem>
                  <FeatureContentListItem>
                    <FeatureContentItemBullet />
                    Supports photos and videos
                  </FeatureContentListItem>
                  <FeatureContentListItem>
                    <FeatureContentItemBullet />
                    Customize slide playback speed, background color and
                    background animation
                  </FeatureContentListItem>
                </FeatureContentList>
              </FeatureSectionContent>
            </FeatureSection>
          </LandingSectionHorizontalLayout>
        </div>
        <div className="bg-brand-mint_green-50">
          <LandingSectionHorizontalLayout>
            <FeatureSection direction="regular">
              <Image
                src="/landing-pages/feature-moderation.webp"
                layout="constrained"
                height={390}
                width={480}
                alt="Guest page screenshot"
              />
              <FeatureSectionContent className="sm:max-w-md">
                <FeatureContentHeading>
                  Moderate what guests can do
                </FeatureContentHeading>
                <FeatureContentDescription>
                  Protect your event from strangers and limit what your guests
                  can do.
                </FeatureContentDescription>
                <FeatureContentList>
                  <FeatureContentListItem>
                    <FeatureContentItemBullet />
                    Turn off uploads from guest
                  </FeatureContentListItem>
                  <FeatureContentListItem>
                    <FeatureContentItemBullet />
                    Password protect gallery uploads and viewing
                  </FeatureContentListItem>
                  <FeatureContentListItem>
                    <FeatureContentItemBullet />
                    Allow guest to see everything or only moderated items
                  </FeatureContentListItem>
                </FeatureContentList>
              </FeatureSectionContent>
            </FeatureSection>
          </LandingSectionHorizontalLayout>
        </div>
      </div>

      <CanvaTemplateSection
        heading="Free Canva Templates For Your Wedding"
        body={() => (
          <>
            Every account gets access to our{" "}
            <span className="font-bold">free</span> QR code canva templates!
            Place your qr code in your wedding invites, day of signage, and
            thank you cards. Make it as simple as possible for your guests to
            share their photos and videos with you.
          </>
        )}
        image={() => (
          <div className="flex h-full w-full flex-1 flex-row-reverse items-center justify-center lg:justify-start">
            <img
              src={ScavengerHunt}
              alt="scavenger hunt"
              className=" h-60 rotate-[16deg] shadow-md md:right-[10%] md:h-80 lg:h-full"
            />
            <img
              src={TableNumber}
              alt="table number"
              className="mr-[-80px] h-60 rotate-[-7deg] shadow-md md:h-80 lg:h-full"
            />
          </div>
        )}
      />
      <PricingSection
        tiers={{
          plus: {
            id: plusEventProduct.id,
            name: plusEventProduct.name,
            priceUSD: plusEventProduct.priceUSD,
            description: Tiers.plus.description,
            bestValue: false,
            features: Tiers.plus.features({
              storageCap: plusEventProduct.storageCap,
              limit: plusEventProduct.limit,
            }),
          },
          premium: {
            id: premiumEventProduct.id,
            name: premiumEventProduct.name,
            priceUSD: premiumEventProduct.priceUSD,
            description: Tiers.premium.description,
            bestValue: true,
            features: Tiers.premium.features({
              storageCap: premiumEventProduct.storageCap,
              limit: premiumEventProduct.limit,
            }),
          },
        }}
      />
      <BlogSection />
      <FaqSection
        image={UsPeaceSign}
        faqs={[
          {
            id: "1",
            question: "How many guest can upload to my event?",
            answer:
              "As many as you like! We do not place any limits on the number of guests that can upload to your event.",
          },
          {
            id: "7",
            question: "Can I use EventShare in another country?",
            answer:
              "Absolutely! As long as you and your guests have access to an internet connection, you can upload photos and videos anywhere in the world.",
          },
          {
            id: "2",
            question: "Which photo and video formats are supported?",
            answer:
              "Most standard photo formats are supported including .jpeg, .png, .svg, .webp, and heic (iPhone). Video formats .mp4, .mov, .mkv, .avi, and .webm can be uploaded, but only .mpv and .mov can be played in the gallery.",
          },
          {
            id: "22",
            question: "How do you handle iPhone pictures?",
            answer:
              'Heic and Heif formats are supported. If a guest uploads a photo using "bounce" or "loop" mode, it will appear as a video in your gallery. "Live", "long exposure", "live off", and "live with portrait enabled" mode will appear as a single photo in your gallery.',
          },
          {
            id: "3",
            question:
              "How do guests find where to upload their photos and videos?",
            answer:
              "Your guests can visit your custom upload page via a link or by scanning the QR code for your event. Your unique QR code is found in your event portal and can easily be customized. ",
          },
          {
            id: "4",
            question: "Can I control who can see my gallery?",
            answer:
              "Absolutely! You can set and share a unique password to control who has access. There are also moderation settings for how you would like photos to be uploaded and viewed. For example, turn off the gallery or set new items to upload as private so they are hidden from the gallery initially.",
          },
          {
            id: "5",
            question: "What happens if I run out of storage for uploads?",
            answer:
              "Not a problem at all. You can always purchase additional storage if needed. The dashboard conveniently shows your data usage and remaining storage.",
          },
          // {
          //   id: "6",
          //   question: "Which package is best for me?",
          //   answer:
          //     "The plus package is perfect for smaller weddings that won't have a lot of videos. Choose our premium package for larger events or if you anticipate lots of video uploads.",
          // },
          // {
          //   id: "7",
          //   question: "Where do you store my photos and videos?",
          //   answer:
          //     "We have partnered with some of the best file storage companies in the world to keep your photos safe. We store multiple copies of each item to ensure they are not lost. You retain all rights to your guests' uploads  and they are removed after your event expires. We promise to never sell your photos and videos.",
          // },
          {
            id: "8",
            question: "How long can I access my event and gallery?",
            answer:
              "All paid plans come with 12 months of storage access. The 12 months of access starts on the activation date you choose and ends one year from that date. During these 12 months, you can collect, share, and download everything. We will send an email reminder to download your event data before the expiration date. Once 12 months have passed, all event data will be deleted.",
          },
          {
            id: "9",
            question: "How should I display my QR code?",
            answer:
              "Your QR code should be printed clearly on light-colored paper and be large enough that guests' phones can read it. Your event includes access to several free Canva templates for wedding signs that you can add your QR code to and edit.",
          },
        ]}
      />
      <FooterSection />
    </div>
  );
}
